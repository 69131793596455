import io from "socket.io-client";

// Global Socket Instance for handling Socket Events
export const notificationSocket = io(
  process.env.NODE_ENV === "production"
    ? "https://uatlite.judo-ai.com/"
    : "http://localhost:4010/",
  {
    path: "/socket.io",
    withCredentials: true,
    transports: ["websocket", "polling", "flashsocket"],
    autoConnect: true,
  }
);

notificationSocket.on("connect", () => {
  console.log("Connected to notificationSocket server");
});

notificationSocket.on("connect_error", (error) => {
  console.error("NotificationSocket Connection error:", error);
});

notificationSocket.on("disconnect", () => {
  console.log("Disconnected from notificationSocket server");
});

notificationSocket.on("reconnect", (attemptNumber) => {
  console.log(
    `Reconnected to notificationSocket server (attempt ${attemptNumber})`
  );
});

notificationSocket.on("reconnect_attempt", () => {
  console.log("Attempting to reconnect to notificationSocket server...");
});

notificationSocket.on("reconnect_error", (error) => {
  console.error("Reconnection error:", error);
});

notificationSocket.on("reconnect_failed", () => {
  console.error("Failed to reconnect to notificationSocket server");
});

export const startNotificationListener = (
  userData,
  setNotifications,
  setUnreadCount,
  openNotification
) => {
  // Listen for 'notification' event from the server
  notificationSocket.on("notification", (notification) => {
    console.log("Received Notification", notification);
    // Update notifications state with the received notification
    setNotifications((prevNotifications) => [
      notification,
      ...prevNotifications,
    ]);
    setUnreadCount((prevUnreadCount) => prevUnreadCount + 1);
    openNotification(notification);
  });

  // // Emit 'join' event with user's ID when connected
  notificationSocket.emit("join", { userMail: userData.email });

  return () => {
    // console.log("Disconnecting from socket server...");
    notificationSocket.off("notification");
  };
};
